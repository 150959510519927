var viewMore = document.querySelectorAll('.js-view-more');

Array.prototype.slice.call(viewMore).forEach(function(viewMore) {

  viewMore.addEventListener('click', function(event) {

    event.preventDefault();

    if (this.classList.contains('closed')) {
      this.classList.remove('closed');
      this.classList.add('open');
    } else {
      this.classList.remove('open');
      this.classList.add('closed');
    }

  });
});
